import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import { getKey } from '../helpers';

import SolutionComponent from '../components/compound/Solution';

/**
 * Solution Component
 * @param {Object} props - component props
 * @returns {JSX}
 */
const Solution = props => {
  const content = getKey(props, 'data.allStrapiSolutions.edges.0.node', {});
  const metaTitle = getKey(props, 'pageContext.metaTitle');
  const location = getKey(props, 'location');

  return (
    <>
      {metaTitle && (
        <Helmet
          title={`Alestra - Soluciones - ${metaTitle}`}
          meta={[
            { content: metaTitle, name: 'description' },
            { content: `${metaTitle}, alestra, soluciones`, name: 'keywords' },
          ]}
        >
          <html lang="es" />
        </Helmet>
      )}
      <SolutionComponent {...content} location={location} />
    </>
  );
};

export default Solution;

export const query = graphql`
  query SolutionsQuery($pageID: String!) {
    allStrapiSolutions(filter:{ id: { eq: $pageID }}) {
      edges {
        node {
          benefits
          benefitsOptions {
            content
            id
          }
          category {
            name
            slug
            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            backgroundImageMobile {
              childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          description
          family {
            name
            slug
            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            backgroundImageMobile {
              childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            gradient {
              end
              start
            }
          }
          features
          featuresOptions {
            content
            id
          }
          name
          slug
          moreInfoTitle
          moreInfo: MoreInfoLinks {
            id
            title
            link
            icon {
              url: publicURL
            }
          }
        }
      }
    }
  }
`;
