import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { scrollToTop } from '../../../store/actions';
import {
  convertParagraphs,
  isValidArray,
  getKey,
  isValidObject
} from '../../../helpers';

import Link from '../../base/Link';
import Styles from './Solution.styles';
import TypeB from '../ActionCard/TypeB';
import ContactForm from '../ContactFormSolutions';

const Container = styled.div`
  ${Styles.container}
`;
const SecondaryDescription = styled.div`
  ${Styles.secondaryDescription}
`;
const SecondaryTitle = styled.h3`
  ${Styles.secondaryTitle}
`;
const List = styled.ul`
  ${Styles.list}
`;
const ItemList = styled.li`
  ${Styles.itemList}
`;
const ItemListBullet = styled.span`
  ${Styles.itemListBullet}
`;
const Wrapper = styled.div`
  ${Styles.wrapper}
`;
const PreviousLink = styled(Link)`
  ${Styles.previousLink}
`;
const MoreInfoList = styled.ul`
  ${Styles.moreInfoList}
`;
const MoreInfoItem = styled.li`
  ${Styles.moreInfoItem}
`;
const MoreInfoItemLink = styled.a`
  ${Styles.moreInfoItemLink}
`;

/**
 * Solution page component
 * @returns {JSX}
 */
const Solution = ({
  benefits,
  benefitsOptions,
  description,
  family,
  features,
  featuresOptions,
  location,
  moreInfo,
  moreInfoTitle,
  name,
}) => {
  const dispatch = useDispatch();

  const gradient = getKey(family, 'gradient');
  const endColor = getKey(gradient, 'end');
  const previousPage = getKey(location, 'state.from');

  useEffect(() => {
    dispatch(scrollToTop());
  }, [dispatch]);

  const renderBenefits = useMemo(() => {
    if (!isValidArray(benefitsOptions)) return null;

    const options = benefitsOptions.map(({ content, id }) => (
      <ItemList key={id}>
        <ItemListBullet color={endColor} />
        {convertParagraphs(content, id)}
      </ItemList>
    ));

    return (
      <List>
        {options}
      </List>
    );
  }, [benefitsOptions, endColor]);

  const renderFeatures = useMemo(() => {
    if (!isValidArray(featuresOptions)) return null;

    const options = featuresOptions.map(({ content, id }) => (
      <ItemList key={id}>
        <ItemListBullet color={endColor} />
        {convertParagraphs(content, id)}
      </ItemList>
    ));

    return (
      <List>
        {options}
      </List>
    );
  }, [featuresOptions, endColor]);

  const renderMoreInfo = useMemo(() => {
    if (!isValidArray(moreInfo)) return null;

    const items = moreInfo.map(({
      icon,
      id,
      link,
      title,
    }) => (
      <MoreInfoItem key={id}>
        <MoreInfoItemLink href={link}>
          {getKey(icon, 'url') && <img src={getKey(icon, 'url')} alt="" />}
          <span>{title}</span>
        </MoreInfoItemLink>
      </MoreInfoItem>
    ));

    return (
      <MoreInfoList>
        {items}
      </MoreInfoList>
    );
  }, [moreInfo]);

  const renderActionCard = useMemo(() => {
    const backgroundImage = getKey(family, 'backgroundImage.childImageSharp.fluid');
    const backgroundImageMobile = getKey(family, 'backgroundImageMobile.childImageSharp.fluid', backgroundImage);
    const breadcrumb = previousPage && (
      <PreviousLink to={previousPage}>
        &laquo; Ver otras soluciones
      </PreviousLink>
    );

    const actionCardProps = {
      backgroundColor: '#f7f7f7',
      backgroundImage,
      backgroundImageMobile,
      breadcrumb,
      callToActionHighlightColor: getKey(gradient, 'start'),
      description: convertParagraphs(description, 'desc'),
      isInterior: true,
      subtitle: getKey(family, 'name'),
      title: name,
    };

    return (
      <TypeB {...actionCardProps} />
    );
  }, [family, gradient, description, name, previousPage]);

  const renderContactForm = useMemo(() => {
    const { formOptions } = family;

    if (!isValidObject(formOptions)) {
      return null;
    }

    const {
      formId,
      formInputValue,
      formLabel,
      portalId,
      solutionformoptions,
    } = formOptions;

    const options = solutionformoptions.map(item => {
      return {
        label: item.familyTitle,
        options: item.options.map(option => ({
          label: option.label,
          value: option.label,
        }))
      };
    });

    return (
      <ContactForm
        description="¿Necesitas asesoría, te interesa mas información sobre alguna de nuestras soluciones o simplemente quieres contactarnos? No lo dudes y escríbenos, nuestros expertos te están esperando."
        formId={formId}
        formInputValue={formInputValue}
        formOptions={options}
        portalId={portalId}
        title={`Somos expertos; acércate a nosotros y asesórate sobre ${formLabel || 'Nuestras Soluciones'}`}
      />
    );
  }, [family]);

  return (
    <Wrapper>
      {renderActionCard}
      <Container>
        <div className="container">
          {(moreInfo && renderMoreInfo) && (
            <div className="row">
              <SecondaryDescription className="col-12 col-md-6" gradient={gradient}>
                <SecondaryTitle>{moreInfoTitle}</SecondaryTitle>
              </SecondaryDescription>
              <SecondaryDescription className="col-12 col-md-6" gradient={gradient}>
                {renderMoreInfo}
              </SecondaryDescription>
            </div>
          )}
          {(features || renderFeatures) && (
            <div className="row">
              <SecondaryDescription className="col-12 col-md-6" gradient={gradient}>
                <SecondaryTitle>Caracteristicas</SecondaryTitle>
              </SecondaryDescription>
              <SecondaryDescription hideInMobile className="col-12 col-md-6" gradient={gradient}>
                {convertParagraphs(features, 'feat')}
              </SecondaryDescription>
              <div className="col-12">{renderFeatures}</div>
            </div>
          )}
          {(benefits || renderBenefits) && (
            <div className="row">
              <SecondaryDescription className="col-12 col-md-6" gradient={gradient}>
                <SecondaryTitle>Beneficios</SecondaryTitle>
              </SecondaryDescription>
              <SecondaryDescription hideInMobile className="col-12 col-md-6" gradient={gradient}>
                {convertParagraphs(benefits, 'benf')}
              </SecondaryDescription>
              <div className="col-12">{renderBenefits}</div>
            </div>
          )}
        </div>
      </Container>
      {renderContactForm}
    </Wrapper>
  );
};

Solution.propTypes = {
  benefits: PropTypes.string,
  benefitsOptions: PropTypes.array,
  description: PropTypes.string,
  family: PropTypes.object,
  features: PropTypes.string,
  featuresOptions: PropTypes.array,
  location: PropTypes.object,
  moreInfo: PropTypes.array,
  moreInfoTitle: PropTypes.string,
  name: PropTypes.string,
};

Solution.defaultTypes = {
  moreInfo: [],
  moreInfoTitle: 'Más información',
};

export default Solution;
