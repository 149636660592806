import { css } from 'styled-components';
import {
  ALABASTER,
  GRADIENT_VIOLET_TO_BLUE,
  MID_GRAY,
  WHITE,
  WOODSMOKE,
  BLACK,
  SHARK,
  CERULEAN,
} from '../../../constants/colors';
import { media, rem, horizontalGradient } from '../../../helpers/mixins';

export default {
  banner: css`
    background-color: ${BLACK};
    height: 200px;
    position: relative;
    width: 100vw;

    ${media.md`
      height: 300px;
    `}
  `,
  bannerDesktop: css`
    display: none;

    ${media.md`
      display: block;
    `}
  `,
  bannerMobile: css`
    display: block;

    ${media.md`
      display: none;
    `}
  `,
  category: css`
    color: ${MID_GRAY};
    font-size: ${rem(15)};
    font-weight: 500;

    ${media.md`
      font-size: ${rem(27)};
    `}
  `,
  container: css`
    background-color: ${WHITE};
    padding-top: 40px;
  `,
  description: css`
    color: ${MID_GRAY};
    font-size: ${rem(15)};
    line-height: ${rem(21)};
    margin-bottom: 50px;
    margin-top: 50px;

    ${media.md`
      columns: 2;
    `}
  `,
  itemList: css`
    break-inside: avoid-column;
    color: ${SHARK};
    display: flex;
    font-size: ${rem(15)};
    line-height: ${rem(24)};
    padding-bottom: 20px;
  `,
  itemListBullet: css`
    align-self: flex-start;
    background: ${CERULEAN};
    border-radius: 10px;
    display: block;
    flex-shrink: 0;
    height: 5px;
    margin-right: 20px;
    margin-top: 7px;
    width: 5px;
  `,
  list: css`
    column-count: 1;
    margin: 0;
    padding: 20px 0 0;

    ${media.md`
      column-count: 2;
      column-gap: 48px;
      padding-bottom: 20px;
    `}
  `,
  moreInfoItem: css`
    margin-bottom: 20px;
  `,
  moreInfoItemLink: css`
    align-items: center;
    display: flex;

    img {
      margin-right: 10px;
      max-height: 40px;
      max-width: 40px;
    }
  `,
  moreInfoList: css`
    column-count: 2;
    list-style: none;
  `,
  previousLink: css`
    color: ${MID_GRAY};
    display: block;
    font-size: ${rem(12)};
    margin-bottom: 20px;

    ${media.md`
      font-size: ${rem(14)};
    `}
  `,
  secondaryDescription: ({ hideInMobile }) => css`
    color: ${MID_GRAY};
    font-size: ${rem(15)};
    line-height: ${rem(24)};

    &::before {
      background: ${CERULEAN};
      content: ' ';
      display: ${hideInMobile ? 'none' : 'block'};
      height: 1px;
      margin-bottom: 25px;
      width: 100%;
    }

    ${hideInMobile && media.md`
      &::before {
        display: block;
      }
    `}
  `,
  secondaryTitle: css`
    color: ${WOODSMOKE};
    font-size: ${rem(18)};
    margin-bottom: 20px;
  `,
  solutionTitle: css`
    font-size: ${rem(24)};
    font-weight: 700;

    ${media.md`
      font-size: ${rem(56)};
      line-height: ${rem(60)};
    `}
  `,
  topContainer: css`
    background-color: ${ALABASTER};
    padding-top: 40px;
  `,
  wrapper: css`
    background-color: ${WHITE};
  `,
};
